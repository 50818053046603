import React from 'react';
import Link from 'next/link'
import { useInView } from 'react-intersection-observer'
import LazyImage from '../LazyImage'

export default function FeaturedContent (props) {
  const renderColumns = items =>
    items.map(items => {
      const image = items.link_image
      const text = items.link_text
      const [slideUpRef, isInView] = useInView({ triggerOnce: true })

      return (
        <React.Fragment key={image.id}>
          <div className='col-12 col-lg-4 link-column'>
            <div
              ref={slideUpRef}
              className={` link_column__content animation ${
                isInView ? 'slide-up' : ''
              }`}
            >
              <LazyImage
                background
                imageClass='link-column__image'
                src={image.url}
              />
              <div className='image__caption col-10'>
                <h2>{items.link_image_caption}</h2>
              </div>
            </div>

            {renderLinks(text)}
          </div>

          <style jsx>{``}</style>
        </React.Fragment>
      )
    })

  const renderLinks = childItems =>
    childItems &&
    childItems.map(items => {
      const [slideUpRef, isInView] = useInView({ triggerOnce: true })
      return (
        <div
          key={items.link_text_heading + items.link_text_link}
          ref={slideUpRef}
          className={`link-column__text animation ${
            isInView ? 'slide-up' : ''
          }`}
        >
          <h3 className='heading3'>
            {
              (items.link_text_link == '*/liftthemask',
              '/news',
              '/press',
              '/stories',
              '/leadership' ? (
                <a href={items.link_text_link} className='link'>
                  {items.link_text_heading}
                </a>
              ) : (
                <Link href={items.link_text_link} passHref>
                  <a className='link'>{items.link_text_heading}</a>
                </Link>
              ))
            }
          </h3>
          <div
            dangerouslySetInnerHTML={{ __html: items.link_text_paragraph }}
          />
        </div>
      )
    })

  return (
    <React.Fragment>
      <div className='container-fluid link-columns'>
        <h3 className='slide-up heading2 link-columns__heading'>
          Featured Content
        </h3>

        <div className='row'>{renderColumns(props.columns)}</div>
      </div>
    </React.Fragment>
  )
}
